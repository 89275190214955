<template>
  <div id="CoachProfiles" class="coach-us">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <p class="bg-tit">
          {{ $t('CoachProfiles.tit') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <!-- <div class="top-right-con"></div> -->
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>
    <div class="center-background">
      <div class="center-container" style="max-width: 1200px; margin: 0 auto">
        <div class="title-text">
          <span>
            <img class="connet" src="../assets/img/2-Icons/icons_connect-left-or.svg" alt="" />
          </span>
          <span class="text-blue">
            {{ $t('HomeNew.title1') }}
          </span>
          <span>
            <img class="connet" src="../assets/img/2-Icons/icons_connect-right-or.svg" alt="" />
          </span>
        </div>
        <div style="width: 80%; margin: 50px auto">
          <div class="swiper-father">
            <swiper :options="swiperOptionCard" v-if="categoriesList.length">
              <swiper-slide class="stop-swiping" align="center" v-for="(item, index) in categoriesList" :key="index">
                <div :class="{ active: position === index, 'sport-box': true }" @click="getDataList('category_id', item.id, index, $event.target, item.name)">
                  <div align="center" class="box1">
                    <img v-show="position === index" class="box-img" :src="item.icon_selected_url" alt="" />
                    <img v-show="position !== index" class="box-img" :src="item.icon_unselected_url" alt="" />
                  </div>
                  <div class="box-txt">
                    <!-- {{ item.name_en.toUpperCase() }} -->
                    {{ $i18n.locale == 'en' ? item.name.toUpperCase() : item.name }}
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev">
              <i class="el-icon-arrow-left el-arrow" />
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="el-icon-arrow-right el-arrow" />
            </div>
          </div>
        </div>
        <div class="create">
          <div class="create-tit">
            {{ $t('CoachProfiles.tit2') }}
          </div>
          <div class="btn-group">
            <div class="create-btn" @click="createProfiles()">
              <div>
                {{ $t('CoachProfiles.btn1') }}
              </div>
              <i class="el-icon-circle-plus-outline"></i>
            </div>
            <div class="search-btn">
              <el-select v-model="search" filterable @change="getDataList('sport_type', search)" :placeholder="$t('CreateProfiles.pleaseselect')">
                <el-option key="all" :label="this.$t('Jobs.screeningTypes1')" value="all"></el-option>
                <el-option v-for="item in sportOption" :key="item.name_en" :label="$i18n.locale == 'en' ? item.name_en : item.name_zh_tw" :value="item.name_en"> </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- <div class="coach-profiles" v-loading="profilesLoading"> -->
        <div class="coach-profiles">
          <!-- coach card -->
          <div class="coach-profile" v-for="(item, index) in coachProfiles" :key="index">
            <div>{{ item.banner_url }}</div>
            <div
              class="profile-bg"
              :style="{
                background: item.banner_url ? `url(${item.banner_url}) no-repeat 100%/100% !important` : `url(${bannerBg}) no-repeat 100%/100% !important`,
              }"
            ></div>
            <div class="profile-bg1"></div>
            <div class="profile-info">
              <div class="coach-info">
                <div
                  class="head-portrait"
                  :style="{
                    backgroundImage: item.cover_url ? `url(${item.cover_url})` : `url(${headerBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                  }"
                ></div>
                <div class="person-info">
                  <div class="coach-name">
                    <span class="person-name">
                      {{ item.name }}
                    </span>
                    <!-- <span>
                                            <img
                                                class="icon"
                                                src="../assets/img/2-Icons/icons_profile-medal.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span>
                                            <img
                                                class="icon"
                                                src="../assets/img/2-Icons/icons_profile-trophy.svg"
                                                alt=""
                                            />
                                        </span> -->
                  </div>
                  <!-- <div class="coach-tit">
                                        {{ $t("CoachProfiles.position") }} {{ item.title }}
                                    </div> -->
                </div>
              </div>
              <!-- <div class="stars">
                                <span>
                                    <img
                                        v-for="(i, o) in 4"
                                        :key="o"
                                        class="star"
                                        src="../assets/img/2-Icons/icons_star.svg"
                                        alt=""
                                    />
                                </span>
                            </div> -->
              <div class="main-sport">
                <span> {{ $t('CoachProfiles.mainsport') }}</span>
                <span v-for="(i, o) in item.main_sport" :key="o">
                  {{
                    styleLang != 'cn'
                      ? sportType.find((one) => one.name_en == i) && sportType.find((one) => one.name_en == i).name
                      : sportType.find((one) => one.name_zh_tw == i) && sportType.find((one) => one.name_zh_tw == i).name
                  }}
                </span>
              </div>
              <div class="sport-btns">
                <div class="sport-btn" v-for="(i, o) in item.Skills" :key="o">
                  {{ i.name && Skills.find((one) => one.value == i.name) ? Skills.find((one) => one.value == i.name).label : i.name }}
                </div>
              </div>
              <div class="info-line"></div>
            </div>
            <div class="button-wrapper">
              <div class="info-btn">
                <button class="save-btn" @click="toMore(item.id)">
                  {{ $t('CoachProfiles.btn2') }}
                </button>
                <button class="save-btn" @click="saveCoach(item.id, item.isSaved)">{{ item.isSaved ? $t('CoachProfiles.btn5') : $t('CoachProfiles.btn3') }}</button>
              </div>
              <div class="info-btn">
                <button class="connect-btn" @click="handleConnect(item.id)">{{ $t('CoachProfiles.btn4') }}</button>
              </div>
            </div>
          </div>
          <!-- coach card end -->
        </div>
        <div class="paginationStyle">
          <el-pagination
            v-if="pagination.total > 0"
            background
            @current-change="getList"
            :current-page.sync="pagination.currentPage"
            :page-size="pagination.pagesize"
            layout="prev, pager, next"
            :total="pagination.total"
          >
          </el-pagination>
          <div v-else>
            {{ $t('Jobs.nodata') }}
          </div>
        </div>
        <!-- <el-button type="text" @click="dialogFormVisible = true">打开嵌套表单的 Dialog</el-button> -->

        <el-dialog :title="$t('CoachProfiles.select_job')" :visible.sync="dialogFormVisible">
          <el-form>
            <el-form-item :label="$t('CoachProfiles.job_name')" label-width="200px">
              <el-select v-model="jobId" :placeholder="$t('CoachProfiles.select_job')" style="width: 300px">
                <el-option v-for="item in myUnconfirmedJobs" :key="item.id" :label="item.index + ' ' + item.event_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">{{ $t('CoachProfiles.cancel') }}</el-button>
            <el-button type="primary" @click="confirmJob()">{{ $t('CoachProfiles.confirm') }}</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { getProfileList, getCategoriesList, getSportTypes, getMyConfirmedJobs, SavedProfiles, unSavedProfiles, getSportsList, getUnconfirmedJobs } from '@/api/coachProfile'
import { putJobInvite } from '@/api/member'
import moment from 'moment'
export default {
  name: 'CoachProfiles',
  data() {
    return {
      position: '',
      pagination: {
        currentPage: 1, // 当前页码
        pagesize: 9, // 每页显示的行数
        tableData: this.coachProfiles,
        total: 0,
      },
      profilesLoading: false,
      search: '',
      screenWidth: window.document.body.clientWidth,
      swiperOption: {
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      swiperOptionCard: {
        pagination: {
          el: '.swiper-pagination',
        },
        loop: true,
        noSwiping: true,
        noSwipingClass: 'stop-swiping',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        speed: 1000,
        slidesPerView: 5,
        spaceBetween: 30,
        // centeredSlides: true,
        watchSlidesProgress: true,
      },
      cardImg: [
        { img: 'icons_ball', txt: this.$t('HomeNew.sport3') },
        { img: 'icons_racket', txt: this.$t('HomeNew.sport4') },
        { img: 'icons_bat', txt: this.$t('HomeNew.sport5') },
        { img: 'icons_all_sports', txt: this.$t('HomeNew.sport1') },
        { img: 'icons_ball', txt: this.$t('HomeNew.sport2') },
      ],
      swiperImg: [
        {
          img: require('../assets/img/0-Banner/20210306-65.jpg'),
        },
      ],
      coachProfiles: [],
      skills: [],
      categoriesList: [],
      sportType: [],
      sportOption: [],
      headerBg: 'https://df7ocie544p6c.cloudfront.net/images/avatar1-20220606.png',
      bannerBg: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_4-20220606.jpg',
      userId: null,
      dialogFormVisible: false,
      myJob: [{ index: '', event_name: '', id: null }],
      myUnconfirmedJobs: [],
      coachProfiles_id: null,
      jobId: null,
      job_message: '',
      Skills: [
        {
          value: 'Motivate',
          label: this.$t('Skills.Motivate'),
        },
        {
          value: 'Passionate_about_sports',
          label: this.$t('Skills.Passionate_about_sports'),
        },
        {
          value: 'Casual',
          label: this.$t('Skills.Casual'),
        },
        {
          value: 'Creative_Thinking',
          label: this.$t('Skills.Creative_Thinking'),
        },
        {
          value: 'Passionate_about_teaching',
          label: this.$t('Skills.Passionate_about_teaching'),
        },
        {
          value: 'Flexibility',
          label: this.$t('Skills.Flexibility'),
        },
        {
          value: 'Confidence',
          label: this.$t('Skills.Confidence'),
        },
        {
          value: 'With_a_clearly',
          label: this.$t('Skills.With_a_clearly'),
        },
        {
          value: 'Goal_oriented',
          label: this.$t('Skills.Goal_oriented'),
        },
        {
          value: 'Knowledgeable',
          label: this.$t('Skills.Knowledgeable'),
        },
        {
          value: 'Careful',
          label: this.$t('Skills.Careful'),
        },
        {
          value: 'Respectful',
          label: this.$t('Skills.Respectful'),
        },
        {
          value: 'Patient',
          label: this.$t('Skills.Patient'),
        },
        {
          value: 'Chatty',
          label: this.$t('Skills.Chatty'),
        },
        {
          value: 'Approachable',
          label: this.$t('Skills.Approachable'),
        },
        {
          value: 'Fair',
          label: this.$t('Skills.Fair'),
        },
        {
          value: 'Logical',
          label: this.$t('Skills.Logical'),
        },
        {
          value: 'Supportive',
          label: this.$t('Skills.Supportive'),
        },
        {
          value: 'Persuasive',
          label: this.$t('Skills.Persuasive'),
        },
        {
          value: 'Calm',
          label: this.$t('Skills.Calm'),
        },
        {
          value: 'Curious',
          label: this.$t('Skills.Curious'),
        },
        {
          value: 'Principled',
          label: this.$t('Skills.Principled'),
        },
        {
          value: 'Systemic',
          label: this.$t('Skills.Systemic'),
        },
        {
          value: 'Adventurous',
          label: this.$t('Skills.Adventurous'),
        },
        {
          value: 'Positive',
          label: this.$t('Skills.Positive'),
        },
        {
          value: 'Optimistic',
          label: this.$t('Skills.Optimistic'),
        },
        {
          value: 'Leadership',
          label: this.$t('Skills.Leadership'),
        },
        {
          value: 'Inspiring',
          label: this.$t('Skills.Inspiring'),
        },
        {
          value: 'Professional',
          label: this.$t('Skills.Professional'),
        },
        {
          value: 'Good_listener',
          label: this.$t('Skills.Good_listener'),
        },
        {
          value: 'Reliable',
          label: this.$t('Skills.Reliable'),
        },
        {
          value: 'Attention_to_detail',
          label: this.$t('Skills.Attention_to_detail'),
        },
        {
          value: 'Self_discipline',
          label: this.$t('Skills.Self_discipline'),
        },
        {
          value: 'Good_communication_skills',
          label: this.$t('Skills.Good_communication_skills'),
        },
        {
          value: 'Harmonious',
          label: this.$t('Skills.Harmonious'),
        },
        {
          value: 'Friendly',
          label: this.$t('Skills.Friendly'),
        },
        {
          value: 'Perseverance',
          label: this.$t('Skills.Perseverance'),
        },
        {
          value: 'Clear_instructions_and_expressions',
          label: this.$t('Skills.Clear_instructions_and_expressions'),
        },
        {
          value: 'Accept_people_with_different_abilities',
          label: this.$t('Skills.Accept_people_with_different_abilities'),
        },
        {
          value: 'Rapid_response',
          label: this.$t('Skills.Rapid_response'),
        },
        {
          value: 'Kind',
          label: this.$t('Skills.Kind'),
        },
        {
          value: 'Tolerant',
          label: this.$t('Skills.Tolerant'),
        },
        {
          value: 'Strict_image',
          label: this.$t('Skills.Strict_image'),
        },
      ],
      apiType: '',
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    this.getSports()
    this.getUserId()
    this.getList()
    this.getMyJob()
    this.getMyUnconfirmedJobs()
    this.getSportsList('All sports')
  },
  methods: {
    getMyUnconfirmedJobs() {
      if (this.userId) {
        getUnconfirmedJobs(this.userId).then((res) => {
          this.myUnconfirmedJobs = res.data
        })
      }
    },
    getMyJob() {
      if (this.userId) {
        getMyConfirmedJobs().then((res) => {
          this.myJob = res.data
          //const jobList = res.data
        })
      }
    },
    handleConnect(id) {
      this.coachProfiles_id = id
      if (this.userId) {
        this.dialogFormVisible = true
      } else {
        this.$prompt.alert(this.$t('CoachProfiles.please3'), this.$t('CoachProfiles.welcome'), {
          confirmButtonText: this.$t('CoachProfiles.confirm'),
          confirmButtonClass: 'box-btn',
        })
      }
    },
    confirmJob() {
      let data = {
        coach_id: this.coachProfiles_id,
        job_id: this.jobId,
        message: this.job_message,
      }
      putJobInvite(data)
        .then((res) => {
          if (res.result == 'success') {
            this.$message({
              message: 'success',
              type: 'success',
            })
          }
          this.dialogFormVisible = false
        })
        .catch((err) => {
          console.log(err)
          this.dialogFormVisible = false
        })
    },
    GetType() {
      // removing the hash from url, to extract the sports id
      return window.location.hash.substring(2)
    },
    getUserId() {
      this.userId = window.sessionStorage.getItem('user_id')
    },
    getSports() {
      getCategoriesList().then((res) => {
        this.categoriesList = [
          {
            icon_selected_url: require('../assets/img/2-Icons/icons_all_sports-or.png'),
            icon_unselected_url: require('../assets/img/2-Icons/icons_all_sports-wh-13.png'),
            id: '',
            name: this.$t('CoachProfiles.All_sports'),
            name_en: 'All sports',
            name_zh_tw: '全部運動',
            sportType: [],
          },
        ]
        this.categoriesList.push(...res.data.data)
      })
      getSportTypes().then((res) => {
        this.sportType = res.data.data
      })
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      return token == null ? false : true
    },
    open() {
      this.$prompt.alert(this.$t('CoachProfiles.please'), this.$t('CoachProfiles.welcome'), {
        confirmButtonText: this.$t('CoachProfiles.confirm'),
        confirmButtonClass: 'box-btn',
      })
    },
    createProfiles() {
      let isLogin = this.checkLogin()
      if (!isLogin) {
        this.open()
      } else {
        this.$router.push('/coach-connect/create-profiles')
      }
    },
    goPush(page) {
      this.$router.push(page)
    },
    getList() {
      this.profilesLoading = true
      let type = this.GetType()
      let page = this.pagination.currentPage
      // checking type in api request.
      const api_Type = this.apiType === 'sport_type' ? 'sport_type' : 'category_id'
      // comparing the type to the default url
      if (type !== 'coach-connect/coach-profiles') {
        getProfileList(page, this.userId, api_Type, type)
          .then((res) => {
            console.log(res)
            this.coachProfiles = res.data.data
            this.pagination.total = res.data.total
            this.pagination.tableData = this.coachProfiles
            this.profilesLoading = false
          })
          .catch(() => {
            this.profilesLoading = false
          })
      } else {
        getProfileList(page, this.userId)
          .then((res) => {
            this.coachProfiles = res.data.data
            this.pagination.total = res.data.total
            this.pagination.tableData = this.coachProfiles
            this.profilesLoading = false
          })
          .catch(() => {
            this.profilesLoading = false
          })
      }
    },
    getDataList(type, name, index, target, itemName = '') {
      // type = 'sport-type'
      if (name == 'all') {
        name = ''
      }
      // assigning "sport_type" as api type
      this.apiType = type
      this.profilesLoading = true
      let page = this.pagination.currentPage
      // the filter works on adding the hash to the url, giving the hash value
      // of the sport id for each sport category
      window.location.hash = name
      getProfileList(page, this.userId, type, name)
        .then((res) => {
          console.log(res.data)
          this.pagination.total = res.data.total
          this.coachProfiles = res.data.data
          this.profilesLoading = false
          this.getSportsList(itemName)
        })
        .catch(() => {
          this.profilesLoading = false
        })
      // }
      if (typeof index === 'number') {
        this.position = index
      }
    },
    toMore(id) {
      console.log(id)
      this.$router.push({ path: '/coach-connect/create-profiles', query: { id: id } })
    },
    saveCoach(id, flag) {
      if (this.userId) {
        if (flag) {
          unSavedProfiles(id).then((res) => {
            this.getList()
          })
        } else {
          SavedProfiles(id).then((res) => {
            this.getList()
          })
        }
      } else {
        this.$prompt.alert(this.$t('CoachProfiles.please3'), this.$t('CoachProfiles.welcome'), {
          confirmButtonText: this.$t('CoachProfiles.confirm'),
          confirmButtonClass: 'box-btn',
        })
      }
    },
    getSportsList(itemName) {
      const searchItem = itemName.search('All sports') === 0 ? '' : itemName
      getSportsList(searchItem).then(({ data }) => {
        let items = data.data
        this.sportOption = []
        items.forEach((sports) => {
          this.sportOption.push({
            name_en: sports.name_en,
            name: sports.name_en,
            name_zh_tw: sports.name_zh_tw,
            id: sports.category_id,
          })
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.ml10 {
  margin-left: 10px;
}
.line {
  background-color: #d2d2d2;
  height: 1px;
  margin: 60px 10%;
}
.center-background {
  background: url('../assets/img/bg-blue.jpg');
}
// .center-container {
//     max-width: 1200px;
//     margin: 0 auto;
// }
.paginationStyle {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0 50px 0;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #f15922; // 进行修改选中项背景和字体
    color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #f15922;
  }
}
.coach-us {
  .banner-con {
    position: relative;
    .top-left-con {
      width: 62%;
      height: 500px;
      background: #28327b;
      position: absolute;
      top: 0;
      left: 0;
      transform: skew(-15deg) translateX(-9%);
      z-index: 2;
      .line-one {
        width: 338px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 24%;
        right: 2%;
      }
      .line-two {
        width: 120px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 42%;
        right: 2%;
      }
      .banner-tit1 {
        width: auto;
        margin-top: 18%;
        margin-left: 2%;
        padding-left: 13%;
        text-align: left;
        font-size: 70px !important;
        color: #fff;
        transform: skew(6deg);
        font-weight: bold;
      }
      .banner-tit2 {
        width: auto;
        margin-top: -2%;
        margin-left: 4%;
        padding-left: 10%;
        text-align: left;
        font-size: 70px !important;
        color: #fff;
        transform: skew(6deg);
        font-weight: bold;
        // font-family: "NotoSansCJKtc-Black";
      }
      .bg-tit {
        margin: 200px auto;
        font-size: 75px;
        font-weight: bold;
        line-height: 75px;
        color: #ffffff;
        font-size: 70px !important;
        color: #fff;
        opacity: 1;

        transform: skew(13deg);
      }
      .more {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        transform: skew(15deg);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .swiper {
      .swiper-pagination {
        display: none !important;
      }
      height: 620px;
      .swiper-item1 {
        position: relative;
        overflow: hidden;
        .box {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 60%;
          overflow: hidden;
          transform: skew(-15deg) translateX(12%);
          .img {
            position: absolute;
            left: -250px;
            bottom: 0;
            height: 100%;
            width: 140%;
            transform: skew(15deg) translateX(-9%);
            object-fit: cover;
          }
        }
        .top-right-con {
          width: 40%;
          height: 9.1%;
          background: #f15922;
          position: absolute;
          top: 3%;
          right: 0;
          transform: skew(-15deg) translateX(2.5%);
        }
        .bottom-left-con {
          width: 40%;
          height: 15%;
          background: #f15922;
          position: absolute;
          bottom: 5%;
          left: 0;
          transform: skew(-15deg) translateX(-3%);
        }
      }
    }
  }
}
.swiper-father {
  position: relative;
}
.swiper-button-next {
  position: absolute;
  right: -10%;
  /*先将原始箭头的颜色设置为透明的  然后设置一个背景图片  达到修改按钮样式的目的*/
  // right: 1.5%;
  // left: auto;
  color: #f15922 !important;
  text-align: center;
  line-height: 50px;
  // background:#f15922;
  // background:url("/assets/images/button_right.png") center center no-repeat;
  background-size: 30px 30px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 50%;
}
.swiper-button-prev {
  position: absolute;
  left: -10%;
  width: 50px;
  height: 50px;
  background: #ffffff;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  // left: 1.5%;
  // right: auto;
  color: #f15922 !important;
  // background:#f15922;
  // background:url("/assets/images/button_left.png") center center no-repeat;
  background-size: 30px 30px;
}
.swiper-button-next,
.swiper-button-prev {
  .el-arrow {
    transform: translateX(1px);
  }
}
img.connet {
  width: 50px;
  margin: 0 10px;
}
.title-text {
  font-size: 30px;
  padding-top: 100px;
}
.text-blue {
  color: #28327b;
  font-weight: bold;
}
.box1 {
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  .box-img {
    width: 100%;
    height: 100%;
  }
}
// .box1:hover {
//     background: #f15922;
// }
.box-txt {
  text-align: center;
  color: #28327b;
  font-weight: bold;
  margin-top: 10px;
}
.box-txt:hover {
  cursor: pointer;
  color: #f15922;
}
.active {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  color: #f15922;
}

.create {
  width: 88%;
  margin: 80px auto;
  display: flex;
  justify-content: space-between;
  .create-tit {
    height: 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 46px;
    color: #28327b;
  }
  .create-btn {
    width: 400px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 31px;
    // width: 357px;
    height: 65px;
    background: #8f98dc;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 600;
    line-height: 65px;
    color: #ffffff;
    .el-icon-circle-plus-outline {
      font-size: 40px;
      margin-left: 20px;
    }
  }
  .create-btn:hover {
    cursor: pointer;
  }
  .el-select {
    width: 100%;
  }
}
.coach-profiles {
  margin: 0 auto;
  width: 88%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .coach-profile {
    width: 435px;
    margin-bottom: 66px;
    background: #ffffff;
    box-shadow: 0px 0px 20px #d1d4eb;
    border-radius: 8px;
    position: relative;
    .profile-bg {
      // background: url("../assets/img/1-Box-Images/images_seminar.png")
      //     no-repeat 100%/100% !important ;
      width: 435px;
      height: 166px;
      border-radius: 8px 8px 0px 0px;
    }
    .profile-bg1 {
      width: 435px;
      height: 85px;
      position: absolute;
      top: 81px;
      background: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, #808080 100%);
    }
    .profile-info {
      padding: 0 20px;
      .coach-info {
        display: grid;
        grid-template-columns: 30% 70%;
        gap: 5px;
        align-items: center;
        position: relative;
        bottom: 2em;
        .head-portrait {
          top: -30px;
          width: 125px;
          height: 124px;
          border: 3px solid #d1d4eb;
          border-radius: 50%;
        }
        .person-info {
          margin-left: 35%;
          margin-top: 15px;
          position: absolute;

          .coach-name {
            font-size: 22px;
            font-weight: bold;
            line-height: 28px;
            color: #28327b;
            display: flex;
            justify-content: center;
            .icon {
              width: 34px;
              height: 34px;
              background: #fbf7f6;
              border-radius: 50%;
              margin: 0 5px;
            }
          }
          .coach-tit {
            width: 100%;
            text-align: left;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            color: #898989;
          }
        }
      }
      .stars {
        text-align: left;
        margin-top: 30px;
        .star {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin: 0 5px;
        }
      }
      .main-sport {
        text-align: left;
        min-height: 80px;
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
        color: #8f98dc;
      }
      .sport-btns {
        display: flex;
        flex-wrap: wrap;
        min-height: 50px;
        height: 80px;
        .sport-btn {
          margin: 5px 0;
          width: 119px;
          height: 30px;
          background: #8f98dc;
          border: 0;
          border-radius: 15px;
          font-size: 14px;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
          margin-right: 5px;
        }
      }
      .info-line {
        margin: 20px 0;
        width: 100%;
        height: 0px;
        border: 1px solid #d1d4eb;
      }
    }
    .info-btn {
      width: 100%;
      display: flex;
      margin: 7px 0;
      justify-content: space-between;
    }
    .button-wrapper {
      padding-inline: 20px;
      .save-btn {
        width: 185px;
        height: 60px;
        border: 1px solid #f15922;
        border-radius: 4px;
        color: #f15922;
        background: #fff;
      }
      .connect-btn {
        width: 100%;
        padding-block: 10px;
        background: #f15922;
        color: #fff;
        border: 1px solid #f15922;
        border-radius: 4px;
        margin: 5px 0 20px 0;
      }
    }
  }
}
.coach-profiles:after {
  content: '';
  width: 435px;
}
.more-btn {
  margin: 30px auto 150px auto;
  width: 348px;
  height: 65px;
  border: 1px solid #f15922;
  // background: #FFF;
  color: #f15922;
  border-radius: 4px;
}
</style>

<style lang="scss">
.box-btn {
  background: #f15922 !important;
  border: #fff;
}
.box-btn:hover {
  background: #f15922;
  border: #fff;
}
#CoachProfiles {
  .swiper-pagination {
    text-align: right !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 15px;
    right: 45px;
    left: unset !important;
    width: 30%;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px !important;
      display: inline-block;
      background: #fff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #f15922;
      opacity: 1;
    }
  }
}
::v-deep {
  .el-message-box {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .el-message-box {
    width: 100%;
  }
  #CoachProfiles {
    .coach-profiles {
      width: 97%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .coach-profile {
        width: 49%;
        overflow: hidden;
      }
      .person-info {
        margin-left: 45%;
      }
    }
    .box1 {
      width: 1.5rem;
      height: 1.5rem;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets {
      top: 15px;
      right: 4%;
      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 50% !important;
      }
    }
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 40.5%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 100%;
          width: 53%;
          .img {
            height: 100%;
            width: 130%;
            right: 0%;
          }
        }
      }
    }
    .top-left-con {
      width: 76%;
      height: 368px;
      top: 0;
      left: -16%;
      .bg-tit {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      //   .bg-tit {
      //     margin-top: 2% !important;
      //     padding-left: 30% !important;
      //     font-size: 35px !important;
      //     padding-left: 0;
      //   }
      .more {
        font-size: 20px;
        bottom: 12px;
        right: 6px;
      }
      .line-two {
        width: 14px;
        top: 33%;
      }
      .line-one {
        width: 60px;
        top: 20%;
      }
    }
    .bottom-left-con {
      width: 48%;
      height: 30%;
      left: -2%;
    }
    .create-btn {
      font-size: 16px;
      width: auto;
    }
  }
}
</style>
